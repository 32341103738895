// material-ui
import { useTheme } from "@mui/material/styles";
import Logosvg from "../assets/images/logo.svg";

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
  // const theme = useTheme();
  // return (
  //   <>
  //     <img src={Logosvg} draggable="false"></img>
  //   </>
  // );
};

export default Logo;
